import * as actionType from "./actionTypes";
import { getRequest, postRequest, processError } from "../../utils/apiHandlers";
import { ENQUIRY_TYPE, LOADING_STATE, LS_ITEM } from "../../utils/stateTypes";
import { loadSavedConsent, saveConsentPreferences, setRlxCookie } from "../localStorage";
import _notif from "../notification";
import { homeSelectors } from "../rootSelectors";

/* ===================================== CATEGORIES & PRODUCTS ===================================== */

export const getCategories = () => (
  (dispatch) => {
    const lsItem = LS_ITEM.CATEGORIES_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequest("/Home/GetCategories",
      (categories) => {
        dispatch({
          type: actionType.RECEIVE_CATEGORIES,
          categories,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const getCategoryDetails = (categoryUrl, redirectTo404) => (
  (dispatch) => {
    const lsItem = `${LS_ITEM.CATEGORY_DETAILS_LOAD}${categoryUrl}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequest(`/Home/GetCategoryDetails/${categoryUrl}`,
      (categoryDetails) => {
        if (!categoryDetails) {
          redirectTo404();
          return;
        }
        dispatch({
          type: actionType.RECEIVE_CATEGORY_DETAILS,
          categoryDetails,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const getBanners = () => (
  (dispatch) => {
    const lsItem = LS_ITEM.BANNERS_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequest("/Home/GetBanners",
      (banners) => {
        dispatch({
          type: actionType.RECEIVE_BANNERS,
          banners,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const getProduct = (productUrl, redirectTo404) => (
  (dispatch) => {
    const lsItem = `${LS_ITEM.PRODUCT_LOAD}${productUrl}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequest(`/Home/GetProduct/${productUrl}`,
      (productDetails) => {
        if (!productDetails) {
          redirectTo404();
          return;
        }
        const product = {
          ...productDetails.product,
          category: productDetails.category,
        };
        dispatch({
          type: actionType.RECEIVE_PRODUCT,
          product,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);


/* ===================================== CONTACT ===================================== */

export const updateContactField = (propToEdit, newValue) => ({
  type: actionType.UPDATE_CONTACT_FIELD,
  propToEdit,
  newValue,
});

export const resetContactFields = () => ({
  type: actionType.RESET_CONTACT_FIELDS,
});

export const setContactFieldsService = () => (
  (dispatch) => {
    dispatch(resetContactFields());
    dispatch(updateContactField("type", ENQUIRY_TYPE.REPAIR));
    dispatch(updateContactField("message", "Good day,\nI would like to book an appointment for a service for the following item:"));
  }
);

export const setContactFieldsProduct = (productName) => (
  (dispatch) => {
    dispatch(resetContactFields());
    dispatch(updateContactField("type", ENQUIRY_TYPE.PRODUCT));
    dispatch(updateContactField("message", `Good day,\nI would like to enquire about the following product: ${productName}`));
  }
);

export const sendMessage = () => (
  (dispatch, getState) => {
    const contactFields = homeSelectors.getContactFields(getState());

    const lsItem = LS_ITEM.CONTACT_SEND;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequest("/Contact/SendContactMessage",
      contactFields,
      () => {
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
        dispatch(resetContactFields());
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);


/* ===================================== SEARCH ===================================== */

export const getSearchResults = (searchQuery) => (
  (dispatch) => {
    const lsItem = LS_ITEM.SEARCH;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequest(`/Home/GetSearchResults/${searchQuery}`,
      (searchResults) => {
        dispatch({
          type: actionType.REFCEIVE_SEARCH_RESULTS,
          searchResults,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);


/* ===================================== CONSENT ===================================== */

export const initConsent = () => (
  (dispatch) => {
    //Check localStorage if consent is already saved
    const savedConsent = loadSavedConsent();
    if (savedConsent === null) {
      //No previous consent, make sure all services are disabled and then show the consent banner
      setRlxCookie(false);
      return dispatch(updateShowConsent(true));
    }
    
    //Has previous consent, check what's allowed and init services accordingly
    if (savedConsent.analytics) {
      // gtagSetup();
    }
    if (savedConsent.rolex) {
      setRlxCookie(true);
    }
  }
);

export const saveConsent = (analytics, rolex) => (
  (dispatch) => {
    //Save the consent preferences in localStorage
    const newSession = saveConsentPreferences({
      analytics,
      rolex,
    });

    //Save the consent on the server, if any consent was given
    if (analytics || rolex) {
      postRequest("/Home/SaveCookieConsent",
        {
          sessionID: newSession,
          cookiePreferences: JSON.stringify({
            analytics,
            rolex,
          }),
        },
        () => {/*ignore*/}, 
        () => {/*ignore*/},
      );
    }

    //Check what's allowed and init services accordingly
    if (analytics) {
      // gtagSetup();
    }
    if (rolex) {
      setRlxCookie(true);
    }
    return dispatch(updateShowConsent(false));
  }
);

export const updateShowConsent = (showConsent) => ({
  type: actionType.SET_SHOW_CONSENT,
  showConsent,
});

/* ===================================== PRESS ===================================== */

export const loadPress = () => (
  (dispatch) => {
    const lsItem = LS_ITEM.PRESS_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequest("/Home/GetPress",
      (press) => {
        dispatch({
          type: actionType.RECEIVE_PRESS,
          pressImages: press.images,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);