import "./footer.css";

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import { LS_ITEM, LOADING_STATE, PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  linkButton: {
    width: "100%",
  },
  linkButtonSkeleton: {
    width: "100%",
    maxWidth: 250,
    height: 25,
    margin: "0 auto 5px auto",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
};


const Footer = ({ timepieceCats, jewelleryCats, categoriesLS }) => {

  return (
    <footer>
      <img
        className={"footer-logo"}
        src={"/images/footer-logo.png"}
      />

      <nav className={"footer-nav-cont"}>
        <div className={"footer-nav-col"}>
          <Link to={PAGE_ROUTES.TIMEPIECES}>
            <h2>TIMEPIECES</h2>
          </Link>
          {
            (categoriesLS === LOADING_STATE.LOADED) ? (
              timepieceCats.map((cat) => (
                <Link
                  to={PAGE_ROUTES.CATEGORY.replace(":categoryUrl", cat.url)}
                  key={cat.id}
                >
                  <Button
                    style={styles.linkButton}
                    color={"secondary"}
                    size={"small"}
                  >
                    {cat.name}
                  </Button>
                </Link>
              ))
            ) : (
              [0, 1, 2, 3, 4, 5].map((i) => (
                <Skeleton
                  key={i}
                  variant={"rectangular"}
                  style={styles.linkButtonSkeleton}
                />
              ))
            )
          }
        </div>
        <div className={"footer-nav-col"}>
          <Link to={PAGE_ROUTES.JEWELLERY}>
            <h2>JEWELLERY</h2>
          </Link>
          {
            (categoriesLS === LOADING_STATE.LOADED) ? (
              jewelleryCats.map((cat) => (
                <Link
                  to={PAGE_ROUTES.CATEGORY.replace(":categoryUrl", cat.url)}
                  key={cat.id}
                >
                  <Button
                    style={styles.linkButton}
                    color={"secondary"}
                    size={"small"}
                  >
                    {cat.name}
                  </Button>
                </Link>
              ))
            ) : (
              [0, 1, 2, 3, 4, 5].map((i) => (
                <Skeleton
                  key={i}
                  variant={"rectangular"}
                  style={styles.linkButtonSkeleton}
                />
              ))
            )
          }
        </div>
        <div className={"footer-nav-col"}>
          <h2>ABOUT US</h2>
          <Link to={PAGE_ROUTES.LEGACY}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              Our Legacy
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.TEAM}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              Meet our team
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.PARTNERS}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              The Charles Greig Gallery
            </Button>
          </Link>
          {/* <Link to={PAGE_ROUTES.GROUP}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              Our Initiatives
            </Button>
          </Link> */}
          <Link to={PAGE_ROUTES.PRESS}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              Media Portal
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.CONTACT}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              Contact us
            </Button>
          </Link>
          <Link to={PAGE_ROUTES.PRIVACY_POLICY}>
            <Button
              style={styles.linkButton}
              color={"secondary"}
              size={"small"}
            >
              Privacy Policies
            </Button>
          </Link>
        </div>
      </nav>

      <div className={"footer-tm"}>
        <img
          className={"footer-logo-2"}
          src={"/images/Jewellery-Council-logo.png"}
        />
        <p>—</p>
      </div>

      <div className={"footer-tm"}>
        &copy; {new Date().getFullYear()} Charles Greig. Site created by
        {" "}
        <a
          href={"https://tap-x.com"}
          target={"_blank"}
          rel={"noreferrer"}
        >
          TapX
        </a>.
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    timepieceCats: homeSelectors.getCategoriesTimepieces(state),
    jewelleryCats: homeSelectors.getCategoriesJewellery(state),
    categoriesLS: notifSelectors.getLoadState(state)(LS_ITEM.CATEGORIES_LOAD),
  };
};

const connected = connect(
  mapStateToProps,
)(Footer);

export default connected;
