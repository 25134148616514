import "./consentBanner.css";

import React from "react";
import { connect } from "react-redux";
import { IconButton, Button, Switch, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { homeSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  closeButton: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  actionButton: {
    flex: 1,
    margin: 5,
    fontSize: "0.8em",
  },
  accordianHeader: {
    backgroundColor: "#f2f2f2",
  },
  accordian: {
    marginBottom: 10,
  },
};

const ConsentBanner = ({
  showConsent,
  initConsent, saveConsent,
}) => {

  React.useEffect(() => {
    initConsent();
  }, []);

  const [viewPreferences, setViewPreferences] = React.useState(false);
  const [analytics, setAnalytics] = React.useState(false);
  const [rolex, setRolex] = React.useState(false);

  if (!showConsent) {
    return null;
  }
  
  return (
    <div className={"consent-banner standard-box"}>
      <IconButton
        style={styles.closeButton}
        size={"small"}
        onClick={() => saveConsent(analytics, rolex)}
      >
        <Close />
      </IconButton>
      <h3>MANAGE COOKIE CONSENT</h3>
      {
        (!viewPreferences) ? (
          <React.Fragment>
            <p className={"consent-description"}>
              To provide the best experiences, we use technologies like cookies to store and/or access device information.
              Consenting to these technologies will allow us to process data such as browsing behaviour or unique IDs on this
              site. Not consenting or withdrawing consent, may adversely affect certain features and functions.
            </p>
            <div className={"consent-buttons-cont"}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={styles.actionButton}
                onClick={() => saveConsent(true, true)}
              >
                Accept
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                style={styles.actionButton}
                onClick={() => saveConsent(false, false)}
              >
                Deny
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                style={styles.actionButton}
                onClick={() => setViewPreferences(true)}
              >
                Preferences
              </Button>
            </div>
            <div className={"consent-links-cont"}>
              <Link to={PAGE_ROUTES.PRIVACY_POLICY}>
                <span>Privacy Policy</span>
              </Link>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                style={styles.accordianHeader}
              >
                <div className={"consent-accordion-header"}>
                  <p>Functional</p>
                  <span className={"consent-active"}>ALWAYS ACTIVE</span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={"consent-accordian-details"}>
                  The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a
                  specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the
                  transmission of a communication over an electronic communications network.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                style={styles.accordianHeader}
              >
                <div className={"consent-accordion-header"}>
                  <p>Analytics</p>
                  <Switch
                    checked={analytics}
                    onChange={(e) => setAnalytics(e.target.checked)}
                    color={"primary"}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={"consent-accordian-details"}>
                  The technical storage or access that is used exclusively for statistical purposes.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion style={styles.accordian}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                style={styles.accordianHeader}
              >
                <div className={"consent-accordion-header"}>
                  <p>Rolex</p>
                  <Switch
                    checked={rolex}
                    onChange={(e) => setRolex(e.target.checked)}
                    color={"primary"}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={"consent-accordian-details"}>
                  Rolex makes use of Adobe Analytics (https://www.adobe.com/privacy/policy.html) and
                  ContentSquare (https://contentsquare.com/privacy-policy/) to collect and store user analytics.
                </p>
              </AccordionDetails>
            </Accordion>
            <div className={"consent-buttons-cont"}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={styles.actionButton}
                onClick={() => saveConsent(true, true)}
              >
                Accept
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                style={styles.actionButton}
                onClick={() => saveConsent(false, false)}
              >
                Deny
              </Button>
              <Button
                variant={"contained"}
                color={"secondary"}
                style={styles.actionButton}
                onClick={() => setViewPreferences(false)}
              >
                Save
              </Button>
            </div>
            <div className={"consent-links-cont"}>
              <Link to={PAGE_ROUTES.PRIVACY_POLICY}>
                <span>Privacy Policy</span>
              </Link>
            </div>
          </React.Fragment>
        )
      }
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    showConsent: homeSelectors.getShowConsent(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    initConsent: _home.actions.initConsent,
    saveConsent: _home.actions.saveConsent,
  },
)(ConsentBanner);

export default connected;