import "./pageCartier.css";

import React from "react";
import { Helmet } from "react-helmet";

const PageCartier = () => {
  const startCartierCorner = () => {
    window.CartierPDGSettings = {
      partner: "64eefe04-6732-4660-927f-aac43ab2a43f",
      language: "en",
      deferred: true,
      targetDivId: "cartier-container",
    };

    let firstScriptTag = document.getElementsByTagName("script")[0];
    let scriptTag = document.createElement("script");
    scriptTag.src = "https://cdn.occtoo.com/cartier/pdg/ux-container/v1/index.min.js";
    scriptTag.async = true;
    scriptTag.onload = () => {
      //Initialize the PDG
      window.CartierPDGUXContainer.init();
    };
    
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
  };

  React.useEffect(() => {
    startCartierCorner();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Cartier Authorized Partner in Sandton & Cape Town – Charles Greig</title>
        <meta
          name={"description"}
          content={"Discover Cartier's signature watch collections, unique pieces, and one-of-a-kind creations at Charles Greig - Authorized partner in Sandton & Cape Town."}
        />
        <meta
          name={"keywords"}
          content={"Cartier, Watches, Luxury, Authorized Partner, South Africa, Sandton, Cape Town, Charles Greig"}
        />
      </Helmet>

      <div id={"cartier-container"} />
      <section className={"cartier-seo-section standard-width"}>
        <h1 className={"cartier-seo-head"}>
          CHARLES GREIG JEWELLERS
          <br />
          <span>OFFICIAL CARTIER RETAILER</span>
        </h1>
        <p className={"about-outlined"}>
          Visit Charles Greig Jewellers at either our Johannesburg or Cape Town store to view
          our extensive collection of Cartier watches. Our expert staff will be able to assist
          you in finding the perfect piece. We are proud to be an official Cartier retailer
          and look forward to welcoming you to our store.
        </p>
      </section>
    </React.Fragment>
  );
};

export default PageCartier;